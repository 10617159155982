/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import de from './de';
import en from './en';
import es from './es';
import esCo from './es-co';
import esMx from './es-mx';
import fr from './fr';
import frca from './fr-ca';
import id from './id';
import it from './it';
import ja from './ja';
import ko from './ko';
import nl from './nl';
import pl from './pl';
import ptBr from './pt-br';
import ru from './ru';
import trTR from './tr-TR';
import zhCn from './zh-cn';
import zhHk from './zh-hk';

const languages = [
  {
    locale: 'en',
    messages: en
  },
  {
    locale: 'en-us',
    messages: en
  },
  {
    locale: 'fr',
    messages: fr
  },
  {
    locale: 'fr-ca',
    messages: frca
  },
  {
    locale: 'fr-fr',
    messages: fr
  },
  {
    locale: 'de',
    messages: de
  },
  {
    locale: 'it',
    messages: it
  },
  {
    locale: 'it-it',
    messages: it
  },
  {
    locale: 'nl',
    messages: nl
  },
  {
    locale: 'tr-TR',
    messages: trTR
  },
  {
    locale: 'tr',
    messages: trTR
  },
  {
    locale: 'es',
    messages: es
  },
  {
    locale: 'es-co',
    messages: esCo
  },
  {
    locale: 'es-mx',
    messages: esMx
  },
  {
    locale: 'id',
    messages: id
  },
  {
    locale: 'ja',
    messages: ja
  },
  {
    locale: 'ko',
    messages: ko
  },
  {
    locale: 'pl',
    messages: pl
  },
  {
    locale: 'pt-br',
    messages: ptBr
  },
  {
    locale: 'ru',
    messages: ru
  },
  {
    locale: 'zh-cn',
    messages: zhCn
  },
  {
    locale: 'zh-hk',
    messages: zhHk
  }
];

export default languages;
